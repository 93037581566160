import React, { useEffect, useState, useRef, useMemo } from "react"
import { withRouter, Link } from "react-router-dom"
import TableContainer from "../../../components/Common/TableContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

import { Name, Email, Tags, Projects, Img } from "./contactlistCol"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions"
import { isEmpty } from "lodash"

//redux
import { useSelector, useDispatch } from "react-redux"
import { apiBase } from "apiConfig"

const KPIUpdate = props => {
  //meta title
  const [propertyBuild, setpropertyBuild] = useState("")
  const [awardsGained, setawardsGained] = useState("")
  const [happyCustomers, sethappyCustomers] = useState("")
  const [experiances, setexperiancesset] = useState("")
  document.title = "User List | Baladi"
  const [contactData, setContactData] = useState([])
  useEffect(() => {}, [])
  return (
    <div style={{ marginTop: 80, padding: 20 }}>
      {/* {JSON.stringify(contactData)} */}
      <div>
        Property Build:{" "}
        <Input
          value={propertyBuild}
          onChange={e => {
            setpropertyBuild(e.target.value)
          }}
        />
      </div>
      <div>
        Awards Gained:{" "}
        <Input
          value={awardsGained}
          onChange={e => {
            setawardsGained(e.target.value)
          }}
        />
      </div>
      <div>
        Happy Customers:{" "}
        <Input
          value={happyCustomers}
          onChange={e => {
            sethappyCustomers(e.target.value)
          }}
        />
      </div>
      <div>
        Years Experience:{" "}
        <Input
          value={experiances}
          onChange={e => {
            setexperiancesset(e.target.value)
          }}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <Button
          onClick={e => {
            updateKPI(
              propertyBuild,
              awardsGained,
              happyCustomers,
              experiances,
              res => {
                try {
                  alert("updated")
                } catch (e) {
                  console.log(e)
                }
              }
            )
          }}
        >
          Update
        </Button>
      </div>
    </div>
  )
}

export default withRouter(KPIUpdate)

function updateKPI(
  propertyBuild,
  awardsGained,
  happyCustomers,
  experiances,
  cb
) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AXL8NxGD9IzqrQ-EIocCoGlC8wuPcyrLj.EjaIF%2B4HRSqNc0JMFhsktU%2BAg5JfZMcyEIXfANZr%2B%2Fg"
  )

  var raw = JSON.stringify({
    propertyBuild,
    awardsGained,
    happyCustomers,
    experiances,
  })

  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  }

  fetch(apiBase + "/Kpis/1", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log("error", error))
}
