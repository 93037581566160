import React, { useEffect, useState } from "react"
import { Col, Container, PaginationItem, PaginationLink, Row } from "reactstrap"
import { withRouter } from "react-router-dom"
import { map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Cards
import CardProject from "./card-project"

import { getProjects as onGetProjects } from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

const ProjectsGrid = props => {
  //meta title
  document.title = "Testimonials Grid"

  const dispatch = useDispatch()

  // const { projects } = useSelector(state => ({
  //   projects: state.projects.projects,
  // }))

  const [page, setPage] = useState(1)
  const [totalPage] = useState(5)
  const [projects, setProjects] = useState([])
  useEffect(() => {
    // dispatch(onGetProjects())
    loadData()
  }, [dispatch])

  const handlePageClick = page => {
    setPage(page)
  }

  const loadData = () => {
    getProjects(result => {
      try {
        console.log(JSON.parse(result))
        setProjects(JSON.parse(result))
      } catch (e) {}
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Projects" breadcrumbItem="Testimonials" />

          <Row>
            {/* Import Cards */}
            <CardProject projects={projects} loadData={loadData} />
          </Row>

          {/* <Row>
            <Col lg="12">
              <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    previous
                    href="#"
                    onClick={() => handlePageClick(page - 1)}
                  />
                </PaginationItem>
                {map(Array(totalPage), (item, i) => (
                  <PaginationItem active={i + 1 === page} key={i}>
                    <PaginationLink
                      onClick={() => handlePageClick(i + 1)}
                      href="#"
                    >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={page === totalPage}>
                  <PaginationLink
                    next
                    href="#"
                    onClick={() => handlePageClick(page + 1)}
                  />
                </PaginationItem>
              </ul>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ProjectsGrid)

function getProjects(cb) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AqyOKlrycbTf0JvQohWN4yOCziPFDrvjp.AjbVO3tvRct0AcrtfDI85SCf%2FEREevGmU9y6ifeiDUs"
  )

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  }

  fetch(
    "https://api.baladi.rayabharitechnologies.com/testimonials",
    requestOptions
  )
    .then(response => response.text())
    .then(result => cb(result))
    .catch(error => console.log("error", error))
}
