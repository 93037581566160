import React, { useEffect, useState, useRef, useMemo } from "react"
import { withRouter, Link } from "react-router-dom"
import TableContainer from "../../../components/Common/TableContainer"
import { Table } from "reactstrap"
import * as Yup from "yup"

//redux

import { apiBase } from "apiConfig"

const ContactsList = props => {
  //meta title
  document.title = "User List | Baladi"
  const [contactData, setContactData] = useState([])
  useEffect(() => {
    loadContacts(res => {
      try {
        setContactData(JSON.parse(res))
      } catch (e) {
        console.log(e)
      }
    })
  }, [])
  return (
    // <div className="page-content" style={{ border: "1px solid red" }}>
    //   content
    // </div>
    <div
      className="page-content"
      style={{
        marginTop: 80,
        padding: 10,
        display: "flex",
        marginBottom: 60,
      }}
    >
      <div
        style={{
          flex: 1,
          // height: "300px",
          overflow: "scroll",
        }}
      >
        <Table bordered>
          <thead>
            <tr style={{}}>
              <td style={{ padding: 10 }}>Name</td>
              <td style={{ padding: 10 }}>email</td>
              <td style={{ padding: 10 }}>Phone</td>
              <td style={{ padding: 10 }}>Message</td>
            </tr>
          </thead>
          <tbody>
            {contactData.map((d, i) => (
              <tr key={"a" + i} style={{ background: "#fff" }}>
                <td style={{ padding: 10 }}> {d.fullName}</td>
                <td style={{ padding: 10 }}> {d.email}</td>
                <td style={{ padding: 10 }}> {d.phone}</td>
                <td style={{ padding: 10 }}> {d.message}</td>
                <td style={{ padding: 10 }}>
                  {" "}
                  {new Date(d.createdAt).toLocaleDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default withRouter(ContactsList)

function loadContacts(cb) {
  var myHeaders = new Headers()
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AYh-OY6KHyyLgOtZ2_OEZq1pyH35-aqLF.9CJpSUnzK0P3e6pADOUK18rvsRQNEyAA2URBpyXUBvg"
  )

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  }

  fetch(apiBase + "/SendUsMessage?sort=createdAt%20DESC", requestOptions)
    .then(response => response.text())
    .then(result => cb(result))
    .catch(error => console.log("error", error))
}
