import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"
import { Link } from "react-router-dom"

import classNames from "classnames"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import WelcomeComp from "./WelcomeComp"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { apiBase } from "apiConfig"

const Dashboard = props => {
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  const reports = [
    { title: "Projects", iconClass: "bx-copy-alt", description: "15" },
    {
      title: "Completed Projects",
      iconClass: "bx-archive-in",
      description: "10",
    },
    {
      title: "Ongoing Projects",
      iconClass: "bx-purchase-tag-alt",
      description: "5",
    },
  ]
  const [projectKpi, setProjectKpi] = useState({})
  useEffect(() => {
    LoadKIP(res => {
      try {
        setProjectKpi(JSON.parse(res))
      } catch (e) {
        console.log(e)
      }
    })
  }, [])

  const [periodData, setPeriodData] = useState([])

  const [periodType, setPeriodType] = useState("yearly")

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  //meta title
  document.title = "Dashboard | Baladi"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="12">
              <Row>
                {/* {reports.map((report, key) => (
                 
                ))} */}
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {"Ongoing Projects"}
                          </p>
                          <h4 className="mb-0">
                            {projectKpi.projectOngoingCount}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + "bx-archive-in" + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {"Upcomming Projects"}
                          </p>
                          <h4 className="mb-0">
                            {projectKpi.projectUpcommingCount}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + "bx-archive-in" + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {"Completed Projects"}
                          </p>
                          <h4 className="mb-0">
                            {projectKpi.projectCompletedCount}
                          </h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "bx " + "bx-archive-in" + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col xl="4">{/* <SocialSource /> */}</Col>
            <Col xl="4">{/* <ActivityComp /> */}</Col>

            <Col xl="4">{/* <TopCities /> */}</Col>
          </Row>

          <Row>
            <Col lg="12">{/* <LatestTranaction /> */}</Col>
          </Row>
        </Container>
      </div>

      {/* subscribe ModalHeader */}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)

function LoadKIP(cb) {
  var myHeaders = new Headers()
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AYh-OY6KHyyLgOtZ2_OEZq1pyH35-aqLF.9CJpSUnzK0P3e6pADOUK18rvsRQNEyAA2URBpyXUBvg"
  )

  var requestOptions = {
    method: "GET",
    headers: myHeaders,

    redirect: "follow",
  }

  fetch(apiBase + "/dashboardKPI", requestOptions)
    .then(response => response.text())
    .then(result => cb(result))
    .catch(error => console.log("error", error))
}
