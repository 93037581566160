import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import { Badge, Card, CardBody, Col, UncontrolledTooltip } from "reactstrap"
import images from "assets/images"
import companies from "assets/images/companies"
import { apiBase } from "../../apiConfig"
const CardProject = ({ projects, loadData }) => {
  return (
    <React.Fragment>
      {map(projects, (project, key) => (
        <Col xl="4" sm="6" key={key}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="avatar-md me-4">
                  <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                    <img src={project.profilePic || ""} alt="" height="30" />
                  </span>
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="text-truncate font-size-15">
                    <Link
                      to={`/projects-overview/${project.id}`}
                      className="text-dark"
                    >
                      {project.name}
                    </Link>
                  </h5>
                  <p className="text-muted mb-4">{project.quote}</p>
                </div>
              </div>
              <button
                onClick={e => {
                  deleteTestimonial(project.id, () => {
                    loadData()
                  })
                }}
              >
                DELETE
              </button>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  )
}

CardProject.propTypes = {
  projects: PropTypes.array,
}

export default CardProject

function deleteTestimonial(id, cb) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append(
    "Cookie",
    "sails.sid=s%3AqyOKlrycbTf0JvQohWN4yOCziPFDrvjp.AjbVO3tvRct0AcrtfDI85SCf%2FEREevGmU9y6ifeiDUs"
  )

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  }

  fetch(apiBase + "/testimonials/" + id, requestOptions)
    // fetch("http://localhost:1337/projects", requestOptions)
    .then(response => response.text())
    .then(result => cb(result))
    .catch(error => console.log("error", error))
}
